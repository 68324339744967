<template>
  <div class="port">
    <div class="l-col">
      <label class="typo__label">{{ $t('label.type') }}</label>
      <base-select
        v-model="formData.protocol"
        select-label=""
        :hide-selected="true"
        :preselect-first="true"
        :required="true"
        :searchable="false"
        placeholder="Выберите подсеть"
        :options="rules"
        size="medium"
        :allow-empty="false"
        class="new-disk__services"
        @input="onChange"
      >
      </base-select>
    </div>
    <div class="l-col">
      <label class="typo__label">{{ $t('label.description') }}</label>
      <base-input
        v-model="formData.description"
        :placeholder="$t('label.placeholder')"
        type="text"
        :label="$t('label.description')"
        use-reactive-validation
        class="form__field--input"
        @input="onChange"
      />
    </div>
    <div class="l-col">
      <label class="typo__label">{{ $t('direction.name') }}</label>
      <base-select
        v-model="formData.direction"
        select-label=""
        :preselect-first="true"
        :hide-selected="true"
        :required="true"
        :searchable="false"
        :custom-label="directionsName"
        placeholder="Выберите подсеть"
        :options="Object.keys(ruleDirections)"
        size="medium"
        :allow-empty="false"
        class="new-disk__services"
        @input="onChange"
      >
      </base-select>
    </div>
    <div v-if="formData.protocol === 'ICMP'" class="l-col port__config">
      <div>
        <base-input
          v-model.number="formData.port.from"
          :placeholder="$t('icmp.type.description')"
          type="text"
          :required="true"
          :label="$t('icmp.type.name')"
          use-reactive-validation
          class="form__field--input"
          @input="onChange"
        />
      </div>
      <div>
        <base-input
          v-model.number="formData.port.before"
          :placeholder="$t('icmp.code.description')"
          type="text"
          :label="$t('icmp.code.name')"
          :required="true"
          use-reactive-validation
          class="form__field--input"
          @input="onChange"
        />
      </div>
    </div>
    <div v-if="formData.protocol === 'OTHER'" class="l-col">
      <div>
        <base-input
          v-model="formData.ip"
          :placeholder="$t('ip.description')"
          type="text"
          :required="true"
          :label="$t('ip.name')"
          use-reactive-validation
          class="form__field--input"
          @input="onChange"
        />
      </div>
    </div>
    <div v-if="['TCP', 'UDP'].includes(formData.protocol)">
      <div class="l-col port__config">
        <div v-for="opt in nav" :key="opt.k" class="port__config">
          <base-radio v-model="port" :value="opt.k" theme="tile" class="port__config-item">
            {{ opt.v }}
          </base-radio>
        </div>
      </div>
      <div v-if="port === '1'" class="l-col">
        <base-input
          v-model.number="formData.port.port"
          :placeholder="$t('port.description.port')"
          type="text"
          :label="$t('port.port')"
          :required="true"
          use-reactive-validation
          class="form__field--input"
          @input="onChange"
        />
      </div>
      <div v-if="port === '2'" class="l-col port__config">
        <div>
          <base-input
            v-model.number="formData.port.from"
            :placeholder="$t('port.description.from')"
            type="text"
            :required="true"
            :label="$t('port.from')"
            use-reactive-validation
            class="form__field--input"
            @input="onChange"
          />
        </div>
        <div>
          <base-input
            v-model.number="formData.port.before"
            :placeholder="$t('port.description.before')"
            type="text"
            :label="$t('port.before')"
            :required="true"
            use-reactive-validation
            class="form__field--input"
            @input="onChange"
          />
        </div>
      </div>
    </div>
    <div class="l-col">
      <label class="typo__label">{{ $t('label.typeNet') }}</label>
      <base-select
        v-model="formData.type"
        select-label=""
        :preselect-first="true"
        :hide-selected="true"
        :required="true"
        :searchable="false"
        :options="typeNet"
        :allow-empty="false"
        class="new-disk__services"
        @input="onChange"
      >
        <!--          placeholder="Выберите подсеть"-->
        <!--          :custom-label="directionsName"-->
      </base-select>
    </div>
    <div class="l-col">
      <label class="typo__label">{{ $t('label.cidr') }}</label>
      <base-input
        v-model="formData.cidr"
        :placeholder="$t('label.cidr')"
        type="text"
        :label="$t('label.cidr')"
        use-reactive-validation
        class="form__field--input"
        @input="onChange"
      />
    </div>
  </div>
</template>

<script>
import setFocus from '@/mixins/setFocus';
import BaseSelect from '@/components/Select/BaseSelect';
import BaseInput from '@/components/BaseInput/BaseInput.vue';
import BaseRadio from '@/components/Checkboxes/BaseRadio.vue';
export default {
  name: 'AddRule',
  components: { BaseInput, BaseSelect, BaseRadio },
  mixins: [setFocus],
  props: {
    instance: {
      type: Object,
      default: () => {},
    },
    router: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      customValidationMsgs: {
        confirm: { patternMismatch: this.$t('error') },
      },
      nav: [
        {
          v: this.$t('nav.port'),
          k: '1',
        },
        {
          v: this.$t('nav.range'),
          k: '2',
        },
        {
          v: this.$t('nav.all'),
          k: '3',
        },
      ],
      id: '',
      reserveIp: [],
      buferIp: '',
      useIp: [],
      ipUsed: [],
      mask: '',
      gateway_ip: [],
      listIp: [],
      pullIp: [],
      newIp: [],
      value: '',
      port: '1',
      formData: {
        protocol: '',
        description: '',
        direction: '',
        type: '',
        ip: '',
        // cidr: this.formData.type === 'IPv4' ? '0.0.0.0/0' : '::/0',
        cidr: '0.0.0.0/0',
        port: {
          port: '',
          from: '',
          before: '',
        },
      },
      tcp_protocols: {
        DNS: 53,
        HTTP: 80,
        HTTPS: 443,
        IMAP: 143,
        IMAPS: 993,
        LDAP: 389,
        MS_SQL: 1433,
        MYSQL: 3306,
        POP3: 110,
        POP3S: 995,
        RDP: 3389,
        SMTP: 25,
        SMTPS: 465,
        SSH: 22,
      },
      ruleDirections: {
        ingress: 'Входящий трафик',
        egress: 'Исходящий трафик',
      },
      typeNet: ['IPv4', 'IPv6'],
    };
  },
  computed: {
    rules() {
      return ['TCP', 'UDP', 'ICMP', 'OTHER', ...Object.keys(this.tcp_protocols)];
    },
    // networks() {
    //   return this.$store.state.moduleStack.networks.filter(x => x.shared === false).map(x => x.id);
    // },
    // subnet() {
    //   const filtredSub = [];
    //   const sub = this.$store.state.moduleStack.subnets;
    //   this.networks.forEach(network => {
    //     filtredSub.push(sub.filter(subnet => subnet.network_id === network));
    //   });
    //   return filtredSub.flat().map(x => x.id);
    // },
    // filtredSub() {
    //   return this.subnet.filter(x => !this.usedSubnets.includes(x));
    // },
    // usedSubnets() {
    //   const usedPorts = this.$store.state.moduleStack.ports.filter(
    //     x => x.device_id === this.router
    //   );
    //   const sub = usedPorts.map(x => x.fixed_ips[0].subnet_id);
    //   return sub;
    // },
    // allPorts() {
    //   return this.$store.state.moduleStack.ports;
    // },
    // allocation_pools() {
    //   return this.id
    //     ? this.$store.state.moduleStack.subnets.find(x => x.id === this.id).allocation_pools
    //     : null;
    // },
  },
  watch: {
    // listIp: function (event) {
    //   // console.log(event);
    //   this.mask = '';
    //   this.value = '';
    //   this.newIp = [];
    //   if (event.length) {
    //     this.useIp = event.map(x => this.getLastIpSection(x));
    //     this.reserveIp = this.getLastIpSection(this.allocation_pools[0].end) + 1;
    //     this.pullIp = Array.from(new Set(this.useIp.concat(this.reserveIp))).sort((a, b) => a - b);
    //     this.pullIp.forEach((x, i) => {
    //       if (this.pullIp[i + 1] - this.pullIp[i] > 1 && i - 1 !== this.pullIp.length) {
    //         this.newIp.push(this.pullIp[i] + 1);
    //       }
    //     });
    //     const ip = this.listIp.length > 0 ? this.listIp[0] : null;
    //     this.mask = this.getMask(ip);
    //     if (ip && this.newIp[0]) this.value = this.mask + this.newIp[0];
    //     // this.buferIp = this.value.length > 0 ? this.value : this.$emit('notready');
    //   } else {
    //     this.pullIp = [
    //       this.getLastIpSection(this.allocation_pools[0].start),
    //       this.getLastIpSection(this.allocation_pools[0].end),
    //     ];
    //     this.pullIp.forEach((x, i) => {
    //       if (this.pullIp[i + 1] - this.pullIp[i] > 1 && i - 1 !== this.pullIp.length) {
    //         this.newIp.push(this.pullIp[i] + 1);
    //       }
    //     });
    //     const ip = this.allocation_pools[0].start;
    //     this.mask = this.getMask(ip);
    //     if (ip && this.newIp[0]) this.value = this.mask + this.newIp[0];
    //     // this.buferIp = this.value.length > 0 ? this.value : this.$emit('notready');
    //     return console.log('список пуст!');
    //   }
    // },
    'formData.type': function (event) {
      this.formData.cidr = event === 'IPv4' ? '0.0.0.0/0' : '::/0';
      // console.log(event);
    },
    // 'formData.cidr': function (event) {
    //   this.formData.cidr = event === 'IPv4' ? '0.0.0.0/0' : '::/0';
    //   console.log(event);
    // },
    port: function (event) {
      this.formData.port.port = event === '3' ? '' : this.formData.port.port;
      this.formData.port.from = event === '3' ? '' : this.formData.port.from;
      this.formData.port.before = event === '3' ? '' : this.formData.port.before;
      this.formData.port.port = event === '2' ? '' : this.formData.port.port;
    },
    gateway_ip: function (event) {
      this.formData.port.fixed_ips[0].ip_address = event;
      return this.$emit('change', {
        port: this.formData.port,
      });
    },
  },
  mounted() {
    this.$emit('notready');
    // this.isReadyNow();
    // this.allocation_pools;
  },

  beforeDestroy() {
    this.id = '';
  },
  methods: {
    // getMask(ip) {
    //   return ip.slice(0, ip.lastIndexOf('.') + 1);
    // },
    // isCorrectIp(ip) {
    //   if (ip !== this.value) {
    //     if (this.getMask(ip) !== this.mask) {
    //       this.$emit('notready');
    //     } else if (this.pullIp.includes(this.getLastIpSection(ip))) {
    //       this.$emit('notready');
    //       this.formData.port.fixed_ips[0].ip_address = '';
    //     } else if (isNaN(this.getLastIpSection(ip))) {
    //       this.formData.port.fixed_ips[0].ip_address = '';
    //       this.$emit('notready');
    //     } else if (
    //       this.getLastIpSection(this.allocation_pools[0].end) < this.getLastIpSection(ip) ||
    //       this.getLastIpSection(this.allocation_pools[0].start) > this.getLastIpSection(ip)
    //     ) {
    //       this.formData.port.fixed_ips[0].ip_address = '';
    //       this.$emit('notready');
    //     } else {
    //       this.value = ip;
    //       this.formData.port.fixed_ips[0].ip_address = ip;
    //       this.$emit('ready', {
    //         port: this.formData.port,
    //       });
    //     }
    //   } else {
    //     this.formData.port.fixed_ips[0].ip_address = ip;
    //     this.$emit('ready', {
    //       port: this.formData.port,
    //     });
    //   }
    // },
    // getLastIpSection(ip) {
    //   return +ip.split('.').at(-1);
    // },
    directionsName(name) {
      return this.ruleDirections[name];
    },
    subnetName(id) {
      const netName = this.$store.state.moduleStack.networks.find(x =>
        x.subnets.find(i => i === id)
      ).name;
      const subnetName = this.$store.state.moduleStack.subnets.find(x => x.id === id).cidr;
      return `${netName} - ${subnetName}`;
    },
    // isReadyNow() {
    //   if (
    //     this.formData.port.fixed_ips[0].subnet_id &&
    //     this.formData.port.fixed_ips[0].ip_address &&
    //     this.formData.port.network_id
    //   ) {
    //     return this.$emit('ready', {
    //       port: this.formData.port,
    //     });
    //   } else {
    //     return this.$emit('notready');
    //   }
    // },
    // isIPv6(addr) {
    //   return /(?:(?:(?:[A-F0-9]{1,4}:){6}|(?=(?:[A-F0-9]{0,4}:){0,6}(?:[0-9]{1,3}\.){3}[0-9]{1,3}(?![:.\w]))(([0-9A-F]{1,4}:){0,5}|:)((:[0-9A-F]{1,4}){1,5}:|:)|::(?:[A-F0-9]{1,4}:){5})(?:(?:25[0-5]|2[0-4][0-9]|1[0-9][0-9]|[1-9]?[0-9])\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)|(?:[A-F0-9]{1,4}:){7}[A-F0-9]{1,4}|(?=(?:[A-F0-9]{0,4}:){0,7}[A-F0-9]{0,4}(?![:.\w]))(([0-9A-F]{1,4}:){1,7}|:)((:[0-9A-F]{1,4}){1,7}|:)|(?:[A-F0-9]{1,4}:){7}:|:(:[A-F0-9]{1,4}){7})(?![:.\w])\/(?:12[0-8]|1[01][0-9]|[1-9]?[0-9])/gim.test(
    //     addr
    //   );
    // },

    onChange(event) {
      // console.log(event);
      if (event === 'IPv4') {
        this.formData.cidr = '0.0.0.0/0';
      }
      if (event === 'IPv6') {
        this.formData.cidr = '::/0';
      }
      // this.formData.cidr = this.formData.type === 'IPv4' ? '0.0.0.0/0' : '::/0';
      if (
        (this.formData.protocol === 'TCP' ||
          this.formData.protocol === 'UDP' ||
          this.formData.protocol === 'ICMP') &&
        this.formData.port
      ) {
        if (this.formData.protocol === 'ICMP') {
          return this.$emit('change', {
            security_group_rule: {
              direction: this.formData.direction,
              security_group_id: this.router,
              description: this.formData.description,
              port_range_min: this.formData.port.from,
              port_range_max: this.formData.port.before ? this.formData.port.before : null,
              protocol: this.formData.protocol,
              ethertype: this.formData.type,
              remote_ip_prefix: this.formData.cidr,
            },
          });
        }
        if (this.port === '1') {
          return this.$emit('change', {
            security_group_rule: {
              direction: this.formData.direction,
              security_group_id: this.router,
              description: this.formData.description,
              port_range_min: this.formData.port.port,
              port_range_max: this.formData.port.port,
              protocol: this.formData.protocol,
              ethertype: this.formData.type,
              remote_ip_prefix: this.formData.cidr,
            },
          });
        } else if (this.port === '2') {
          return this.$emit('change', {
            security_group_rule: {
              direction: this.formData.direction,
              security_group_id: this.router,
              description: this.formData.description,
              port_range_min: this.formData.port.from,
              port_range_max: this.formData.port.before,
              protocol: this.formData.protocol,
              ethertype: this.formData.type,
              remote_ip_prefix: this.formData.cidr,
            },
          });
        } else {
          return this.$emit('change', {
            security_group_rule: {
              direction: this.formData.direction,
              description: this.formData.description,
              security_group_id: this.router,
              // port_range_min: null,
              // port_range_max: null,
              protocol: this.formData.protocol,
              ethertype: this.formData.type,
              remote_ip_prefix: this.formData.cidr,
            },
          });
        }
      }
      if (this.formData.protocol === 'ICMP' && this.formData.port.from) {
        return this.$emit('change', {
          security_group_rule: {
            direction: this.formData.direction,
            security_group_id: this.router,
            protocol: this.formData.protocol,
            description: this.formData.description,
            ethertype: this.formData.type,
            remote_ip_prefix: this.formData.cidr,
          },
        });
      }
      if (this.formData.protocol === 'OTHER') {
        return this.$emit('change', {
          security_group_rule: {
            direction: this.formData.direction,
            description: this.formData.description,
            security_group_id: this.router,
            protocol: this.formData.ip,
            ethertype: this.formData.type,
            remote_ip_prefix: this.formData.cidr,
          },
        });
      }
      if (
        this.formData.protocol !== 'OTHER' &&
        this.formData.protocol !== 'TCP' &&
        this.formData.protocol !== 'UDP' &&
        this.formData.protocol !== 'ICMP'
      ) {
        return this.$emit('change', {
          security_group_rule: {
            direction: this.formData.direction,
            security_group_id: this.router,
            description: this.formData.description,
            protocol: 'TCP',
            port_range_min: this.tcp_protocols[this.formData.protocol],
            port_range_max: this.tcp_protocols[this.formData.protocol],
            ethertype: this.formData.type,
            remote_ip_prefix: this.formData.cidr,
          },
        });
      }

      // if (this.subnet.includes(event)) {
      //   console.log(event);
      // this.gateway_ip = this.$store.state.moduleStack.subnets.find(
      //   x => x.id === event
      // ).gateway_ip;
      // this.formData.port.fixed_ips[0].ip_address = this.gateway_ip;
      // console.log(this.$store.state.moduleStack.subnets.find(x => x.id === event).network_id);
      // this.id = event;
      // this.formData.port.network_id = this.$store.state.moduleStack.subnets.find(
      //   x => x.id === event
      // ).network_id;
      // const port = this.$store.state.moduleStack.ports;
      // const updatePort = port.map(x => x.fixed_ips.filter(a => a.subnet_id === event));
      // const formatPort = updatePort.reduce((acc, item) => {
      //   if (item !== []) acc.push(item);
      //   return acc;
      // }, []);
      // this.listIp = formatPort
      //   .filter(x => x[0])
      //   .map(x => x[0].ip_address)
      //   .sort((a, b) => a.split('.').at(-1) - b.split('.').at(-1));
      // } else {
      //   Object.assign(this.formData, event);
      // console.log(event); //в этом моменте надо делать проверку адреса введенного, который является event
      // this.isCorrectIp(event);
      // if (
      //   this.formData.port.fixed_ips[0].subnet_id &&
      //   this.formData.port.fixed_ips[0].ip_address &&
      //   this.formData.port.network_id
      // ) {
      //   return this.$emit('change', {
      //     port: this.formData.port,
      //   });
      // } else return this.$emit('notready');
      // }
      // },
    },
  },
};
</script>

<i18n>
{
  "ru": {
    "label": {
      "disk": "Источник диска",
      "error": "Свободные порты закончились",
      "type": "Протокол",
      "placeholder": "Пояснение, если необходимо",
      "description": "Описание",
      "cidr": "Маска подсети",
      "typeNet": "Тип сети"
    },
    "direction": {
      "name": "Направление"
    },
    "ip": {
      "name": "IP протокол",
      "description": "От -1 до 255"
    },
    "icmp": {
      "type": {
        "name": "Тип",
        "description": "От -1 до 255"
      },
      "code": {
        "name": "Код",
        "description": "От -1 до 255"
      }
    },
    "error": "Не верный формат",
    "port": {
      "port": "Порт:",
      "from": "От порта:",
      "before": "До порта:",
      "description": {
        "from": "от 1",
        "before": "до 65535",
        "port": "от 1 до 65535"
      }
    },
    "sure": {
      "confirm": "Увеличить"
    },
    "nav": {
      "port" : "Порт",
      "range" : "Диапазон портов",
      "all" : "Все порты"
    }
  }
}
</i18n>

<style lang="stylus" scoped>
@require '~@/assets/styles/vars/variables';
@require '~@/assets/styles/mixins/mixins';
.port {
  text-overflow: unset;
  overflow: visible;
  &__config {
    display: flex;
    justify-content: space-around;

    &-item {
    }
  }

  &-row{
    display: flex;
    flex-direction: row;
    align-items: center;
    margin: 1.5rem 1.5rem;
&-input {
  padding: 1.5rem 1.5rem;
}
  }
}
</style>
